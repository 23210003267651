exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-become-agent-tsx": () => import("./../../../src/pages/become-agent.tsx" /* webpackChunkName: "component---src-pages-become-agent-tsx" */),
  "component---src-pages-crypto-exchange-tsx": () => import("./../../../src/pages/crypto-exchange.tsx" /* webpackChunkName: "component---src-pages-crypto-exchange-tsx" */),
  "component---src-pages-developer-api-tsx": () => import("./../../../src/pages/developer-api.tsx" /* webpackChunkName: "component---src-pages-developer-api-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-micro-loans-tsx": () => import("./../../../src/pages/micro-loans.tsx" /* webpackChunkName: "component---src-pages-micro-loans-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-remittance-tsx": () => import("./../../../src/pages/remittance.tsx" /* webpackChunkName: "component---src-pages-remittance-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

